body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: #000;
  color: white;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
  display: block;
  width: 60vw;
  padding: 10px;
  margin: auto;
  margin-bottom: 15px;
  color: white;
  border: none;
  border-radius: 5px;
}
img {
  max-width: 90vw;
  max-height: 90vh;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: block;
  box-shadow: 0px 0px 10px black;
}
input {
  box-sizing: border-box;
  display: block;
  padding: 10px;
  width: 60vw;
  margin: auto;
  margin-bottom: 15px;
}
h1 {
  text-align: center;
  font-size: 5vh;
  text-shadow: 0px 0px 5px black;
}

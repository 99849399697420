.link {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
  text-shadow: 0px 0px 5px black;
  padding: 10px;
  text-decoration: none;
  box-sizing: border-box;
}

.link:hover {
  color: yellow;
  transform: rotate(180deg);
  transition: 0.5s;
}

.maincontainer {
  position: relative;
  dispaly: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}
